/*const colors = {*/
/*    fixablyBlack: '#1d1e2d',*/
/*    fixablyPrimary: '#007cba',*/
/*    fixablySecondary: '#ff7c34',*/
/*    fixablyWhite: '#ffffff',*/
/*};*/

.pinField > input.-focus {
    border-color: #007cba;
}

.pinField > input.-success {
    border-color: #007cba;
}

.pinField > input {
    width: 70px;
    height: 70px;
    margin: 3px;
    font-size: 30px;
    text-align: center;
    border-color: #007cba;
    border-radius: 6px;
}

.react-pdf__Page__annotations.annotationLayer {
    display: none;
}