/*
<div class="notifications-wrapper">
  <div class="notifications-{position}"> <!-- '{position}' can be one of the positions available: ex: notifications-tr -->
    <div class="notification notification-{level} notification-{state} {notification-not-dismissible}"> <!-- '{level}' can be: success | error | warning | info. '{state}' can be: visible | hidden. {notification-not-dismissible} is present if notification is not dismissible by user -->
      <h4 class="notification-title">Default title</h4>
      <div class="notification-message">Default message</div>
      <span class="notification-dismiss">×</span>
      <div class="notification-action-wrapper">
        <button class="notification-action-button">Action button</button>
      </div>
    </div>
  </div>
</div>
 */

/*const colors = {*/
/*    fixablyBlack: '#1d1e2d',*/
/*    fixablyPrimary: '#007cba',*/
/*    fixablySecondary: '#ff7c34',*/
/*    fixablyWhite: '#ffffff',*/
/*};*/

.notification {
    /*font-family: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",*/
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif !important;*/
    font-size: 16px !important;
    font-weight: 500 !important;
    background-color: #ffffff !important;
    padding: 15px !important;
    border-width: 2px !important;
    border-radius: 6px !important;
}

.notification .notification-dismiss {
    top: 12px !important;
    right: 12px !important;
    transform: scale(1.0);
    color: #ffffff !important;
}

.notification .notification-title {
    font-weight: 700 !important;
    font-size: 16px !important;
}

.notification.notification-success, .notification.notification-info {
    border-color: #007cba !important;
}

.notification.notification-success .notification-dismiss, .notification.notification-info .notification-dismiss {
    background-color: #007cba !important;
}

.notification.notification-success .notification-title, .notification.notification-info .notification-title {
    color: #007cba !important;
}

.notification.notification-warning, .notification.notification-error {
    border-color: #ff7c34 !important;
}

.notification.notification-warning .notification-dismiss, .notification.notification-error .notification-dismiss {
    background-color: #ff7c34 !important;
}

.notification.notification-warning .notification-title, .notification.notification-error .notification-title {
    color: #ff7c34 !important;
}
