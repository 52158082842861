.toolbar-navigation {
  height: 100%;
}

.toolbar-container {
  /*background-image: url('../../assets/logo_placeholder.png');*/
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: left;
  background-position-y: center;
  margin-left: 16px;
  margin-right: 16px;
  height: calc(100% - 24px);
  flex: 1;
}

/*.toolbar-container-logo-desktop {*/
/*  background-image: url('../../assets/logo_placeholder.png');*/
/*}*/

/*.toolbar-container-logo-mobile {*/
/*  background-image: url('../../assets/logo_placeholder_mobile.png');*/
/*}*/

