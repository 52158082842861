.drawer-icon-container {
  position: relative;
  margin-right: 16px;
  padding: 8px;
}

.drawer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606060;
}

.drawer-icon:before {
  margin-top: 1px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: rgb(238,238,238);
  transition: transform 80ms linear;
}

.drawer-icon:hover:before {
  transform: translate(-50%, -50%) scale(1);
  transition: transform 80ms linear;
}

.drawer-icon:after {
  margin-top: 1px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: rgb(222,222,222);
  transition: transform 80ms linear;
}

.drawer-icon:active:after {
  transform: translate(-50%, -50%) scale(1);
  transition: transform 80ms linear;
}

.drawer-icon:hover {
  cursor: pointer;
}
