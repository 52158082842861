html {
    height: 100vh;
}

/*.react-pdf__Document {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

/*.react-pdf__Page {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

/*.react-pdf__Page__canvas {*/
/*    min-width: auto !important;*/
/*    min-height: auto !important;*/
/*    width: auto !important;*/
/*    height: auto !important;*/
/*}*/